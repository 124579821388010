/** @format */

@import url("https://fonts.googleapis.com/css2?family=Paytone+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800&display=swap");
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
}

// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                               loader
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
.loader {
  background: linear-gradient(180deg, #ff512f 6.65%, #dd2476 94.86%);

  z-index: 2 !important;
  position: absolute;
  top: 0px;
}

a {
  text-decoration: none !important;
}

.face .container {
  margin: auto;
  position: relative;
}
.face {
  // margin-top: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden !important;
  z-index: 1;
}
.loading {
  width: 130px;
  display: block;
  height: 2px;
  margin: 28px auto;
  border-radius: 2px;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.loading:before {
  content: "";
  height: 3px;
  width: 68px;
  position: absolute;
  -webkit-transform: translate(-34px, 0);
  -ms-transform: translate(-34px, 0);
  transform: translate(-34px, 0);
  background-color: #dd2476;
  border-radius: 2px;
  -webkit-animation: initial-loading 1.5s infinite ease;
  animation: animation 1.4s infinite ease;
}
@-webkit-keyframes animation {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
@keyframes animation {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
.face .container > img {
  // background: #0274b3;
  // -webkit-box-shadow: 0px 7px 20px 2px rgba(167, 167, 167, 0.6);
  // box-shadow: 0px 7px 20px 2px rgba(167, 167, 167, 0.6);
  border-radius: 2px;
  animation: bounce 1.4s ease infinite;
  -webkit-animation: bounce 1.4s ease infinite;
  -moz-animation: bounce 1.4s ease infinite;
  -ms-animation: bounce 1.4s ease infinite;
  -o-animation: bounce 1.4s ease infinite;
}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0);
    filter: blur(0);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-filter: blur(1.4);
    filter: blur(1.4);
    // -webkit-box-shadow: 0px 0.3px 5px 0.5px rgb(167, 167, 167);
    // box-shadow: 0px 0.3px 5px 0.5px rgb(167, 167, 167);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0);
    filter: blur(0);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-filter: blur(1.4);
    filter: blur(1.4);
    // -webkit-box-shadow: 0px 0.3px 5px 0.5px rgb(167, 167, 167);
    // box-shadow: 0px 0.3px 5px 0.5px rgb(167, 167, 167);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.caption {
  margin-top: 80px;
  text-align: center;
}
.caption h2 {
  font-family: "Paytone One";

  color: #ffffff;
  margin: 0 0 8px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.caption ul {
  margin: 0;
  padding: 0;
}

.caption li {
  list-style: none;
  display: inline-block;
  letter-spacing: 3px;
  font-size: 18px;
  color: #ffffff;
  font-family: "Open Sans";

  margin-right: 5px;
  font-family: Arial Narrow, sans-serif;
  animation: on-run 2.6s ease infinite;
  -webkit-animation: on-run 2.6s ease infinite;
  -moz-animation: on-run 2.6s ease infinite;
  -ms-animation: on-run 2.6s ease infinite;
  -o-animation: on-run 2.6s ease infinite;
}
.caption li:nth-child(1) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.caption li:nth-child(2) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin-right: 10px;
}
.caption li:nth-child(3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.caption li:nth-child(4) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.caption li:nth-child(5) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  margin-right: 10px;
}
.caption li:nth-child(6) {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
.caption li:nth-child(7) {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}
.caption li:nth-child(8) {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}
@-webkit-keyframes on-run {
  0% {
    color: #f3f3f3;
  }
  25% {
    color: #ff512f;
  }
  50% {
    color: #ff512f;
  }
  75% {
    color: #f3f3f3;
  }
  100% {
    color: #f3f3f3;
  }
}
@keyframes on-run {
  0% {
    color: #f3f3f3;
  }
  25% {
    color: #ff512f;
  }
  50% {
    color: #ff512f;
  }
  75% {
    color: #f3f3f3;
  }
  100% {
    color: #f3f3f3;
  }
}
.caption li:last-child {
  margin-right: 0;
}

body {
  scroll-behavior: smooth;
  user-select: none !important;
  overflow-x: hidden !important;
}
::-webkit-scrollbar-track {
  background: #fff !important;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #ff512f 6.65%, #dd2476 94.86%);
  border-radius: 16px !important;
}
::-webkit-scrollbar {
  width: 5px !important;
}
a {
  text-decoration: none !important;
}

.navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-right: 20px;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none !important;
}
.navbar-toggler-icon {
  width: 24px !important;
  height: 17px !important;
  background-image: none !important;
  position: relative !important;
  border-bottom: 3px solid rgb(255, 255, 255) !important;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px !important;
  position: absolute !important;
  height: 2px !important;
  background-color: rgb(255, 255, 255) !important;
  top: 0 !important;
  left: 0 !important;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after {
  top: 8px !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}
@mixin heading {
  font-family: "Paytone One";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  background: linear-gradient(180deg, #ff512f 0%, #dd2476 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.nav-item {
  .nav-link {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0px 20px;
  }
}

.nav-btn {
  background: #fff;
  border: none;
  border-radius: 100px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 170%;
  padding: 9px 16px;
  color: #ff512f;
}

.hero-bg {
  background: url(./media/bgshapes.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  h1 {
    font-family: "Paytone One";
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 93px;
    color: #ffffff;
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 130%;
    color: #ffffff;
  }
  button {
    background: #fff;
    border: none;
    border-radius: 100px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    padding: 16px 32px;
    color: #ff512f;
  }
}

.video-bg {
  background: url(./media/Groups.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #161124 !important;
  border-radius: 50px;
  height: 600px !important;
  width: 315px !important;
  position: absolute;
  overflow: hidden !important;

  video {
    display: block !important;
    position: relative;
    left: 3.5%;
    // top: -4%;
    width: 288px;
    border-radius: 50px !important;
    // background-color: #DD2476 !important;
    overflow: hidden !important;

    object-fit: contain !important;
  }
}

.sec1 {
  margin-top: 200px;
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    text-align: center;
    color: #2c3a4b;
    margin-top: 30px;
  }
  h1 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 130%;
    text-align: center;
    color: #ff6262;
  }
}

.sec1-content {
  h1 {
    @include heading();
  }
  h3 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 130%;
    /* or 62px */

    text-align: center;
    letter-spacing: -0.02em;
    color: #2c3a4b;
  }
}

.sec1-cards {
  background: #ffffff !important;
  /* Card shadow */
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 2px 4px -2px rgba(24, 39, 75, 0.08) !important;
  border-radius: 24px !important;
  border: none;
  text-align: center;
  p {
    font-family: "Paytone One";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #e27f34;
    margin-top: 10px;
  }
  h3 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    color: #e27f34;
  }
}

.heading {
  font-family: "Paytone One";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #2c3a4b;
  margin: 30px 0px;
}


.center-button {
  background: linear-gradient(180deg, #ff512f 0%, #dd2476 100%);
  border-radius: 100px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 170%;
  color: #ffffff;
  width: 200px;
  text-align: center;
  margin: auto;
  border: none;
  padding: 16px 32px;
}
/* 16 */
.btn-16 {
  border: none;
  color: #000;
}
.btn-16:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}
.btn-16:hover {
  color: #000;
}
.btn-16:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}
.btn-16:active {
  top: 2px;
}

.publish-bg {
  background: url(./media/BG.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // height: 100vh;

  .publish-heading {
    @include heading();
  }

  .publish-card {
    background: #ffffff;
    border-radius: 32px;
    border: none;
    h1 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      text-align: center;
      color: #2c3a4b;
      margin: 20px 0px;
    }
    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 170%;
      letter-spacing: 0.005em;
      color: #2c3a4b;
    }
  }
}

.dashboard {
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #f9b972;
    margin-top: -30px;
    margin-bottom: 20px;
  }

  ul {
    li {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 170%;
      color: #2c3a4b;
      border-left: 4px solid #ffefdd;
      border-radius: 6px;
      margin: 10px 0px;
    }
  }
  button {
    width: 236px;
  }
}

.success-heading {
  @include heading();
  margin: 20px 0px;
}

.success-card {
  background: #fff7f4;
  border-radius: 32px;
  border: none;
  .inner-div {
    background: #fff;
    width: 250px;
    padding: 4px;
    align-items: center;
    border-radius: 10px;
    margin-top: 10px;
    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      margin-top: 10px;
      margin-left: 10px;
      font-size: 16px;
      line-height: 170%;
      letter-spacing: 0.005em;
      color: #e27f34;
    }
    svg {
      background: rgba(249, 185, 114, 0.1);
      border-radius: 6px;
      width: 50px;
      height: 50px;
      color: #e27f34;
      padding: 10px;
    }
  }
  .inner-div1 {
    p {
      color: #06c270;
    }
    svg {
      background: rgba(6, 194, 112, 0.1);
      color: #06c270;
    }
  }
  h1 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #2c3a4b;
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #2c3a4b;
  }
  .quote {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #2c3a4b;
  }
  button {
    width: 171px;
    height: 44px;
    background: linear-gradient(180deg, #ff512f 0%, #dd2476 100%);
    border-radius: 100px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.008em;
    color: #ffffff;
    border: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  h3 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #2c3a4b;
    margin-top: 30px;
  }
  h6 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.008em;
    color: #2c3a4b;
  }
}

.banner-bg {
  background: url(./media/Group1.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 231px;
  h1 {
    font-family: "Paytone One";
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 91px;
    text-align: center;
    color: #ffffff;
  }
  button {
    background: #ffffff;
    border-radius: 100px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    text-align: center;
    color: #ff512f;
    padding: 18px 32px;
    border: none;
    margin-left: 20px;
  }
}

.footer-bg {
  background: url(./media/BG1.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.footer-content {
  h1 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    text-transform: uppercase;
    color: #ffffff;
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #ffffff;
  }
  a {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    color: #ffffff;
    text-decoration: none;
  }
  svg {
    color: #fff;
    width: 30px;
    height: 30px;
    margin: 0px 10px;
  }
}

.footer-bottom {
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
    color: rgba(244, 246, 249, 0.75);
  }
}

@media only screen and (max-width: "600px") {
  .new-sec-bg{
    display: none !important;
  }
  .video-res {
    display: none;
  }
  .res-para {
    p {
      text-align: justify;
    }
  }
  .heading {
    font-size: 30px;
  }
  .wrapper {
    .card {
      margin: 20px 0px;
    }
  }
  .res-btn {
    width: 80% !important;
  }
  .res-img {
    width: 100%;
  }
  .navbar {
    background: linear-gradient(180deg, #ff512f 6.65%, #dd2476 94.86%);
  }
  .res-banner {
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 30px;
    }
    button {
      padding: 6px 12px;
    }
  }
  .line-break {
    display: none;
  }
  .hero-bg {
    h1 {
      font-size: 50px;
      line-height: 53px;
    }
    .publish-heading {
      @include heading();
      font-size: 28px !important;
      background: red;
    }
  }
  .publish-card {
    margin: 20px 0px;
  }
  .success-card {
    margin: 20px 0px;
  }

  .footer-img-content {
    margin: 20px 0px;
  }
}

.btn {
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  font-weight: 700;
  font-size: 15px;
  background-color: #222;
  padding: 17px 60px;
  // margin: 0 auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.btn span {
  position: relative;
  z-index: 1;
}

.btn:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 400%;
  width: 150%;
  background: #dd2476;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
  transform: translateX(-98%) translateY(-25%) rotate(45deg);
}

.btn:hover:after {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

.wrapper {
  .card {
    height: 360px;
    border-radius: 24px;
    padding: 1.5rem;
    // background: white !important;
    position: relative;
    display: flex;
    border: none !important;
    align-items: flex-end;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.2);
    
  }
  .card:hover {
    transform: translateY(10px);
  }
  .card:hover:before {
    opacity: 1;
  }
  .card:hover .info {
    opacity: 1;
    transform: translateY(20px);
  }
  .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
  }
  .card .top-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
    
  }
  .card .info {
    position: relative;
    z-index: 3;
    color: white;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s;
    top: 30%;
  }
  .card .info h1 {
    margin: 0px;
    font-size: 23px;
    font-weight: 500;
  }
  .card .info p {
    letter-spacing: 1px;
    font-size: 15px;
    margin-top: 8px;
  }
  .card .info button {
    padding: 0.6rem;
    outline: none;
    border: none;
    border-radius: 3px;
    background: white;
    color: black;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s ease;
  }
  .card .info button:hover {
    background: dodgerblue;
    color: white;
  }
}

.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 0px auto;
  height: 55px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-1 {
  background-image: linear-gradient(
    to right,
    #ff512f,
    #dd2476,
    #dd2476,
    #ff522f
  );
  box-shadow: 0 4px 15px 0 #ff512f;
}

.new-sec-bg {
  background: url(./media/BG1.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // height: 90vh;
  width: 100%;
  clip-path: circle(99.7% at 51% 12%);
}

.new-sec-p {
  color: #fff;
  font-family: "inter";
  font-size: 18px;
  font-weight: 400;
  width: 70%;
  text-transform: capitalize;
}

.newsection {
  .cards {
    display: flex;
    height: 80vh;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap !important;
    .istcard {
      background-size: cover;
      flex: 0.4;
      background-color: rgba(255, 255, 255, 0.292);
      // height: 80vh;
      border-radius: 50px;
      margin-right: 3vw;
      position: relative;
      background-position: left;
      overflow: hidden;
      height: 40vh;
      padding: 20px;
      &:hover {
        height: 55vh;
        flex-grow: 1;

        background: #fc772f42;
        box-shadow: 2px 2px 30px 4px rgba(30, 30, 30, 0.441);
        // color: rgb(0, 0, 0) !important;
      }
      img {
        position: absolute;
        bottom: 0;
        left: 0;
      }
      h2 {
        font-family: "inter";
        font-size: 40px;
        font-weight: 700;
        // width: 200px;
        position: absolute;
        right: 10%;
        top: 5%;
        text-align: right;
        text-transform: uppercase;
        color: #fff;
      }
    }
    .ndcard {
      background-size: cover;
      flex: 0.4;
      background-color: rgba(255, 255, 255, 0.292);

      // height: 80vh;
      border-radius: 50px;
      margin-right: 3vw;
      width: 500px !important;
      position: relative;
      background-position: center;
      overflow: hidden;
      height: 60vh;
      img {
        position: absolute;
        bottom: 4%;
        left: 4%;
      }
      h2 {
        font-family: "inter";
        font-size: 40px;
        font-weight: 700;
        // width: 200px;
        position: absolute;
        right: 10%;
        top: 5%;
        text-align: right;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 100px;
      }

      h1 {
        position: absolute;
        // bottom: 0;
        right: 10%;
        max-width: 400px;
        text-align: right;
        top: 30%;
        // padding: 20px 0 50px 25px;
        font-family: sans-serif;
        font-weight: 400;
        color: white;
        font-size: 1rem;
        width: 100%;
        // background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
        opacity: 0;
        transition: 0.5s all !important;
        transition-delay: 0.2s !important;
      }

      &:hover {
        background: #fc772f42;
        box-shadow: 2px 2px 30px 4px rgba(30, 30, 30, 0.441);
        // color: rgb(0, 0, 0) !important;
      }
    }

    // .card1:hover, .card2:hover, .card3:hover, .card4:hover, .card5:hover {
    //   flex-grow: 3;

    // }

    h1 {
      position: absolute;
      // bottom: 0;
      right: 10%;
      text-align: right;
      top: 20%;
      // padding: 20px 0 50px 25px;
      font-family: sans-serif;
      font-weight: 400;
      color: white;
      font-size: 1.2rem;
      width: 100%;
      // background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
      opacity: 0;
      transition: 0.5s all !important;
      transition-delay: 0.2s !important;
    }

    .card1:hover h1,
    .card2:hover h1,
    .card3:hover h1,
    .card4:hover h1,
    .card5:hover h1 {
      opacity: 1;
      // color: #000 !important;
    }
  }
}
* {
  transition: 0.8s !important;
}
